import { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import LoginButton from "../components/Login";


const LoginPage = () => {
    const { isAuthenticated } = useAuth0();
    const navigate = useNavigate();

        useEffect(() => {
        // navigate home if user is not authenticated
        if (!isAuthenticated) {
          navigate('/');
        }
    }, [isAuthenticated])

    return (
        <>
            <div className="login-banner">
                <h1>Welcome to COACHE&rsquo;s Reporting Page</h1>
                <div>Please login to see your reports</div>
            </div>
            <div className="login-button">
                <LoginButton />
            </div>
        </>
    )
}

export default LoginPage;