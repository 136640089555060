const Subsection4 = () => {
    return (
        <>
            <h4>References</h4>
            <div>
                <p>
                    The following works were cited in this report or are relevant to the present line of inquiry.
                </p>
                <p>
                    Daly, C. J., & Dee, J. R. (2006). Greener pastures: Faculty turnover intent in urban public universities. <em><em>The Journal of Higher Education</em></em>, 77(5), 776-803.
                </p>
                <p>
                    Jayakumar, U. M., Howard, T. C., Allen, W. R., & Han, J. C. (2009). Racial privilege in the professoriate: An
                    exploration of campus climate, retention, and satisfaction. <em>The Journal of Higher Education</em>, 80(5), 538-563.
                </p>
                <p>
                    Johnsrud, L. K., & Heck, R. H. (1994). A university&rsquo;s faculty: identifying who will leave and who will stay. <em>Journal for Higher Education Management</em>, 10(1), 71-84.
                </p>
                <p>
                    Johnsrud, L. K., & Rosser, V. J. (2002). Faculty members&rsquo; morale and their intention to leave: A multilevel
                    explanation. <em>The Journal of Higher Education</em>, 73(4), 518-542.
                </p>
                <p>
                    Kaminski, D., & Geisler, C. (2012). Survival analysis of faculty retention in science and engineering by gender.
                    <em> Science</em>, 335(6070), 864-866.
                </p>
                <p>
                    Lennartz, C. & O&rsquo;Meara, K. (2018). Navigating a foggy climate: women associate professors&rsquo; sense of agency
                    and work environment experiences. <em>In Success After Tenure: Supporting Mid-Career Faculty</em>, Baker, V. (Ed.),
                    285-310. Sterling, VA: Stylus.
                </p>
                <p>
                    Matier, M. W. (1990). Retaining faculty: A tale of two campuses. <em>Research in Higher Education</em>, 31(1), 39-60.
                </p>
                <p>
                    O&rsquo;Meara, K. (2015). Half-way out: How requiring outside offers to raise salaries influences faculty retention and
                    organizational commitment. <em><em>Research in Higher Education</em></em>, 56(3), 279-298.
                </p>
                <p>
                    O&rsquo;Meara, K., Lounder, A., & Campbell, C. M. (2014). To Heaven or Hell: Sensemaking about Why Faculty
                    Leave. <em>The Journal of Higher Education</em>, 85(5), 603-632.
                </p>
                <p>
                    O&rsquo;Meara, K., Niehaus, E., Bennett, J. (2016). Left unsaid: The role of psychological contracts in faculty careers
                    and departure. <em>Review of Higher Education</em>, 39(2), 269- 297.
                </p>
                <p>
                    Ridgeway, C., & Correll, S. (2004). Unpacking the gender system: a theoretical perspective on gender beliefs
                    and social relations. Gender & Society, 18(4), 510-531.
                </p>
                <p>
                    Rosser, V. J., & Townsend, B. K. (2006). Determining public 2-year college faculty&rsquo;s intent to leave: An empirical
                    model. <em>The Journal of Higher Education</em>, 77(1), 124-147.
                </p>
                <p>
                    Smart, J. C. (1990). A causal model of faculty turnover intentions. <em>Research in Higher Education</em>, 31(5), 405-424.
                </p>
                <p>
                    Trower, C. A. (2012). Success on the tenure track: Five keys to faculty job satisfaction. <em>Baltimore: Johns Hopkins University Press</em>
                </p>
                <p>
                    Weiler, W. C. (1985). Why do faculty members leave a university? <em>Research in Higher Education</em>, 23(3), 270-278.
                </p>
                <p>
                    Wolf-Wendel, L., Rice, S., & Twombly, S. (2003). The two-body problem: dual-career-couple hiring policies in higher education. <em>Baltimore: Johns Hopkins University Press</em>
                </p>
                <p>
                    Xu, Y. J. (2008). Gender disparity in STEM disciplines: A study of faculty attrition and turnover intentions.
                    <em>Research in Higher Education</em>, 49(7), 607-624.
                </p>
                <p>
                    Zhou, Y., & Volkwein, J. F. (2004). Examining the influences on faculty departure intentions: A comparison of
                    tenured versus nontenured faculty at research universities using NSOPF-99. <em>Research in Higher Education</em>,
                    45(2), 139-176.
                </p>
            </div>
            
        </>
    )
}

export default Subsection4;