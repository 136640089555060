
import { useParams } from "react-router-dom";

const Introduction = () => {
  const { institution } = useParams();

    return (
        <>
            <h2>Introduction</h2>
            <p>
                We invite our partners to include faculty who qualify for survey eligibility as “preemptive retentions,” if they
                can be identified. These faculty, without an outside offer, were offered or negotiated for themselves some
                change to their work or employment. They are routed through a much shorter version of the COACHE Faculty
                Retention & Exit Survey.
            </p>
            <p>
                Research strongly suggests, but has not yet determined, that preemptive interventions that improve the
                circumstances of faculty are more successful at retaining them than are eleventh-hour actions taken under a
                proven threat of departure (O&rsquo;Meara, 2015). Rather than demand a formal, written offer before considering
                better resources or supports, universities could instead require proof of an invitation to interview. They can
                offer financial incentives for excellence in teaching, program development, various forms of “engagement,” or
                other contributions to work the university purports to value but has not written into tenure and promotion
                criteria (O&rsquo;Meara, 2015). Other approaches to preemptive retention include institutional awards, publicly
                recognizing research, and any programs that build organizational commitment through stronger and more
                authentic relationships with faculty and administrators (Trower, 2012).
            </p>
            <p>
                In our instrument, we ask preemptive retentions:
            </p>
            <blockquote>
                Which of the following statements describe the changes made to your work or employment at <span className="red-variable-name">{institution}</span>? Mark all that apply.
            </blockquote>
            <p>
                Respondents make selections from a range of over a dozen options, from base salary to assistance with a
                spouse/partner employment search. We follow up with open-ended questions that allow respondents to
                describe their situations with the nuance they often deserve.
            </p>
            <blockquote>
                How, if at all, have the recent change(s) to your work or employment influenced your plans to remain
                at this institution?
            </blockquote>
            <blockquote>
                Please provide any additional details you wish to share about changes to your work or employment.
            </blockquote>
            <p>Data gathered from these open-ended questions appear in your <em>Text Response Report.</em></p>

            <h3>Questions to Consider</h3>
            <ul>
                <li>
                    
                    Take note not just of the most frequently selected items (typically compensation), but of the unselected
                    items, too. Are there opportunities to retain faculty who need support for spousal/partner employment,
                    for example, that the university is wasting?
                </li>
                <li>
                    Are faculty in your department, school, or college made aware of what is negotiable in a potential preemptive retention action? Especially when financial constraints prohibit your university from “spreading
                    the wealth,” are there more creative, equitable and mission-aligned tools of supporting and appreciating
                    faculty work?
                </li>
            </ul>
            
        </>
    )
}

export default Introduction;