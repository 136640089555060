

const Introduction = () => {
    return (
        <>
            <h2>Introduction</h2>
            <p>
                Our research design seeks to shed light not just on the causes, but on the conduct of retention and departure
                processes. Even though the causes may sometimes be beyond a chair&rsquo;s or dean&rsquo;s control, their processes of
                notification and negotiation deserve scrutiny because such “unscripted interactions” (Ridgeway & Correll, 2004)
                in “foggy climates” (Lennartz & O&rsquo;Meara, 2018) are circumstances where inequities thrive. Counting on
                instinct, chairs and deans introduce variability in how faculty are treated during the process and how equitably
                resources are distributed. These should be issues of great concern.
            </p>
            <p>
                The report organizes these items into two categories: the process and the terms. Timeliness and transparency
                in these processes can have an impact on how faculty feel about the home institution. How the process is
                conducted sends a signal about whether deans and chairs care. Such a signal is not trivial: our pilot study suggest
                that faculty feel best about negotiations when they believe that the institution is taking their needs seriously.
                The signal can be the difference between an untimely departure and a successful retention, especially in
                negotiations where the outside offer and counteroffer are not so far apart.
            </p>
            <p>
                With an eye toward helping administrators refine retention intervention strategies, this section continues the
                inquiry into conduct by investigating the most frequent communication channels used by faculty who are
                considering outside offers. They might use these data to learn whether there is a relationship between the
                communication channel (chair, dean, colleague), the institution&rsquo;s overall handling of the process, and the
                outcome (departure, retention).
            </p>
            <p>
                Understanding the conduct of successful counteroffers—and of those where someone “dropped the ball”—
                could help provosts refine their expectations of retention processes and equip deans and chairs with the
                information and tools necessary to respond efficiently to outside offers. Balancing the needs of the department,
                the division, and the institution can be a difficult line to walk. Does your institution prepare your chairs to do
                it?
            </p>

            <h3>Questions to Consider</h3>
            <ul>
                <li>
                    What training do chairs receive to prepare them for negotiating when faculty receive outside offers?
                </li>
                <li>
                    How might chairs of larger departments with more frequent faculty turnover share expertise with chairs of
                    smaller departments that may be navigating the retention process for the first time in many years?
                </li>
                <li>
                    Are there differences between men and women, white faculty and faculty of color in how they are treated
                    (or how they are perceived they are treated)?
                </li>
                <li>
                    What institutional mechanisms can be put in place to ensure equitable negotiation processes?
                </li>
            </ul>
            
        </>
    )
}

export default Introduction;