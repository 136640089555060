import { FC } from "react";

interface FilterDropdownProps {
    setSelectedFilter: (HTMLSelectElement: string) => void;
}

const FilterDropdown: FC<FilterDropdownProps> = ( { setSelectedFilter } ) => {

    return (
        <div>
            <select aria-label="Filter" onChange={(e) => setSelectedFilter(e.target.value)}>
                <option value="exit_status">Overall</option>
                <option value="gender">Gender</option>
                <option value="race_ethnicity">Race/Ethnicity</option>
                <option value="tenure_status">Tenure Status</option>
                <option value="discipline">Discipline</option>
            </select>
        </div>
    )
}

export default FilterDropdown;