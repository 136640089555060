import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { getProtectedResource } from "../../services/apiRequest.service";
import { NestedResponseData } from "../../models/responseData";
import {
  TransformedData,
  transformDataStackedByAnswer
} from "../../utils/transformDataUtils";
import FilterDropdown from "../../components/FilterDropdown";
import StackedByAnswer from "../../charts/stackedBarChart/stackedByAnswer";
import InstitutionN from "../../components/InstitutionN";
import PageLoader from "../../components/PageLoader";
import ApiResponseToCSVConverter from "../../utils/csvDownload/ApiResponseToCsvConverter";
import { determineResponseTypeNestedResponseData } from "../../utils/determineResponseType";


const FirstNotification = () => {
  const [selectedFilter, setSelectedFilter] = useState("exit_status");
  const [apiResponse, setApiResponse] = useState<NestedResponseData | null>(null);
  const [transformedResponse, setTransformedResponse] = useState<TransformedData[] | null>(null);
  const [institutionN, setInstitutionN] = useState(0);

  const { getAccessTokenSilently } = useAuth0();
  const { year } = useParams();

  useEffect(() => {
    const getData = async () => {
      const accessToken = await getAccessTokenSilently();
      const requestUrl = `section5/subsection1/?report_year=${year}&group_by=${selectedFilter}`;
      const { data, error } = await getProtectedResource(accessToken, requestUrl);

      if (data && determineResponseTypeNestedResponseData(data)) {
        setApiResponse(data);
        setTransformedResponse(transformDataStackedByAnswer(data, "5.1", selectedFilter));
        setInstitutionN(data.institution.total.total);
      }

      if (error) {
        console.error("Error fetching data:", error);
      }
    };

    getData();
  }, [getAccessTokenSilently, selectedFilter, year]);

  return (
    <>
      <h2>5.1 Your institution&rsquo;s first notification of outside offer</h2>
      <p>How did your institution first learn about the job offer?</p>

      {!transformedResponse && <PageLoader />}

      {transformedResponse &&
        <>
          <InstitutionN institutionN={institutionN} />
          <FilterDropdown setSelectedFilter={setSelectedFilter} />
          <StackedByAnswer
            data={transformedResponse}
            section="5.1"
            institutionN={institutionN}
            selectedFilter={selectedFilter}
          />
          <ApiResponseToCSVConverter
            apiResponse={apiResponse}
            selectedFilter={selectedFilter}
            filename="5.1_Institutions_first_notification"
            section="5.1"
          />
        </>
      }

      
    </>
  )
}

export default FirstNotification;