import { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { determineNdisplay, convertToPercentString } from "../../utils/utils";
import { getProtectedResource } from "../../services/apiRequest.service";
import { ResponseData } from "../../models/responseData";
import TableToCSVConverter from "../../utils/csvDownload/TableToCsvConverter";
import PageLoader from "../../components/PageLoader";
import { determineResponseTypeResponseData } from "../../utils/determineResponseType";


const MissingData = () => {
  const tableRef = useRef<HTMLTableElement>(null);
  const [response, setResponse] = useState<ResponseData | null>(null);
  const { getAccessTokenSilently } = useAuth0();
  const { year } = useParams();
  const [loading, setLoading] = useState(true);
  const [table, setTable] = useState<HTMLTableElement | null>(null);

  useEffect(() => {
    const getData = async () => {
      const accessToken = await getAccessTokenSilently();
      const requestUrl = `appendix/subsection8/?report_year=${year}`;
      const { data, error } = await getProtectedResource(accessToken, requestUrl);

      if (data && determineResponseTypeResponseData(data)) {
        setResponse(data);
        setLoading(false);
      }

      if (error) {
        console.error("Error fetching data:", error);
      }
    };

    getData();
  }, [getAccessTokenSilently, year]);

  useEffect(() => {
    if (tableRef.current) {
      setTable(tableRef.current);
    }
  }, [response]);

  return (
    <>
      <h2>Missing data</h2>
      <p>
        What was data was requested by COACHE, but was not provided in your institution&rsquo;s population file?
      </p>

      {!response && <PageLoader />}
      {response &&
        <>
          <table ref={tableRef}>
            <thead>
              <tr>
                <th></th>
                <th colSpan={2} className="institution-cohort-header">Your Institution</th>
                <th colSpan={2} className="institution-cohort-header">Cohort</th>
              </tr>
              <tr className="column-headers">
                <th className="row-header"></th>
                <th>#</th>
                <th>%</th>
                <th>#</th>
                <th>%</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th className="row-header">Valid n</th>
                <td>{determineNdisplay(response.institution.total, false, true)}</td>
                <td></td>
                <td>{determineNdisplay(response.cohort.total, false, true)}</td>

                <td></td>
              </tr>
              <tr>
                <th className="row-header">Missing Gender</th>
                <td>{determineNdisplay(response.institution.missing_gender, false, true)}</td>
                <td>{convertToPercentString(response.institution.missing_gender, response.institution.total, true)}</td>
                <td>{determineNdisplay(response.cohort.missing_gender, false, true)}</td>
                <td>{convertToPercentString(response.cohort.missing_gender, response.cohort.total, true)}</td>
              </tr>
              <tr>
                <th className="row-header">Missing Race/Ethnicity</th>
                <td>{determineNdisplay(response.institution.missing_race_ethnicity, false, true)}</td>
                <td>{convertToPercentString(response.institution.missing_race_ethnicity, response.institution.total, true)}</td>
                <td>{determineNdisplay(response.cohort.missing_race_ethnicity, false, true)}</td>
                <td>{convertToPercentString(response.cohort.missing_race_ethnicity, response.cohort.total, true)}</td>
              </tr>
              <tr>
                <th className="row-header">Missing Tenure Status</th>
                <td>{determineNdisplay(response.institution.missing_tenure_status, false, true)}</td>
                <td>{convertToPercentString(response.institution.missing_tenure_status, response.institution.total, true)}</td>
                <td>{determineNdisplay(response.cohort.missing_tenure_status, false, true)}</td>
                <td>{convertToPercentString(response.cohort.missing_tenure_status, response.cohort.total, true)}</td>

              </tr>
              <tr>
                <th className="row-header">Missing Discipline</th>
                <td>{determineNdisplay(response.institution.missing_discipline, false, true)}</td>
                <td>{convertToPercentString(response.institution.missing_discipline, response.institution.total, true)}</td>
                <td>{determineNdisplay(response.cohort.missing_discipline, false, true)}</td>
                <td>{convertToPercentString(response.cohort.missing_discipline, response.cohort.total, true)}</td>

              </tr>
              <tr>
                <th className="row-header">Missing apptyear</th>
                <td>{determineNdisplay(response.institution.missing_apptyears, false, true)}</td>
                <td>{convertToPercentString(response.institution.missing_apptyears, response.institution.total, true)}</td>
                <td>{determineNdisplay(response.cohort.missing_apptyears, false, true)}</td>
                <td>{convertToPercentString(response.cohort.missing_apptyears, response.cohort.total, true)}</td>

              </tr>
              <tr>
                <th className="row-header">Missing grants_number</th>
                <td>{determineNdisplay(response.institution.missing_grants_number, false, true)}</td>
                <td>{convertToPercentString(response.institution.missing_grants_number, response.institution.total, true)}</td>
                <td>{determineNdisplay(response.cohort.missing_grants_number, false, true)}</td>
                <td>{convertToPercentString(response.cohort.missing_grants_number, response.cohort.total, true)}</td>
              </tr>
              <tr>
                <th className="row-header">Missing grants_value</th>
                <td>{determineNdisplay(response.institution.missing_grants_value, false, true)}</td>
                <td>{convertToPercentString(response.institution.missing_grants_value, response.institution.total, true)}</td>
                <td>{determineNdisplay(response.cohort.missing_grants_value, false, true)}</td>
                <td>{convertToPercentString(response.cohort.missing_grants_value, response.cohort.total, true)}</td>
              </tr>
              <tr>
                <th className="row-header">Missing aa_quintile</th>
                <td>{determineNdisplay(response.institution.missing_aa_quintile, false, true)}</td>
                <td>{convertToPercentString(response.institution.missing_aa_quintile, response.institution.total, true)}</td>
                <td>{determineNdisplay(response.cohort.missing_aa_quintile, false, true)}</td>
                <td>{convertToPercentString(response.cohort.missing_aa_quintile, response.cohort.total, true)}</td>
              </tr>
              <tr>
                <th className="row-header">Missing aa_fspi</th>
                <td>{determineNdisplay(response.institution.missing_aa_fspi, false, true)}</td>
                <td>{convertToPercentString(response.institution.missing_aa_fspi, response.institution.total, true)}</td>
                <td>{determineNdisplay(response.cohort.missing_aa_fspi, false, true)}</td>
                <td>{convertToPercentString(response.cohort.missing_aa_fspi, response.cohort.total, true)}</td>
              </tr>
            </tbody>
          </table>
          <TableToCSVConverter
            table={table}
            filename="Appendix_8_Missing_data"
            loading={loading}
            setLoading={setLoading}
          />
        </>
      }

      
    </>
  )
}

export default MissingData;