const YourCommitmentToConfidentiality = () => {

    return (
        <>
            <h3>Your Commitment to Confidentiality
            </h3>
            <p>
                In order to comply with the instructions given this study by the Committee on the Use of Human Subjects at Harvard University, the Statement of Data Confidentiality below was signed by the person or people identified by your institution to receive this COACHE data.
            </p>
            <p className="confidentiality-commitment-text">
                I, the undersigned, as Participating Institution&rsquo;s designee, hereby agree that my access to the COACHE Reports will be in accordance with the expectation of total respondent confidentiality. I wish not to receive, nor shall I distribute any Reports, in whole or in part, in which identification of individuals may be possible. Only aggregate data, with no cells fewer than five respondents, will be shared with broader audiences at my institution.
            </p>

            <p>
                COACHE researchers take reasonable, IRB-sanctioned steps to protect the identities of respondents and the confidentiality of their responses. Nevertheless, individual respondent identification may become possible when individuals share, in their text responses, a characteristic specific to their identity such as gender, race/ethnicity, or department, thus posing a potential breach of their confidentiality. In general, our researchers take a heavy hand in redacting such words and phrases, but we make no guarantee that we have redacted all possible instances of “deductive disclosure”.
            </p>
            <p>
                To prevent tracing of the identity of individuals, readers of COACHE reports must not share any results in which identification may be possible. For COACHE to do its work and for our partners to benefit from this work, we must take all precautions to protect the confidentiality of respondents in both their quantitative responses as well as their open-text responses. If that trust is violated, even incidentally, it will have a serious impact on COACHE&rsquo;s ability to study faculty and on your institution&rsquo;s ability to work with the results.
            </p>
            <p>
                Because this report was securely delivered only to a designated report recipient, that person assumes responsibility for preventing further dissemination of self-identifying comments from respondents. In the event of a transition in personnel, the Statement of Data Confidentiality should be signed by whomever assumes primary responsibility for these data.
            </p>
            <h4 className="italicize-sub-header">Strategies for reading these comments
            </h4>
            <p>
                In the 15 years that COACHE has been handling confidential data, we have identified some strategies for our partners&rsquo; proper handling of open-ended responses.
            </p>
            <p>
                Readers, duty-bound, are instructed to proceed not as you would any administrative report, but instead, in a special frame of mind. This document is likely to contain the candid—and perhaps even raw—experiences of your faculty. Many feel that they have taken a risk in sharing their perspectives with COACHE (and with you), yet did so, anyway, in the hope that someone will listen and act to improve the experiences of others. Therefore, respect their efforts by letting go of any inclination even to wonder at the identity of the respondents. Instead, absorb and learn from the individuals who have taken the time to tell us what they might never have told administrators and colleagues at your institution.

            </p>
            <p>
                Next, readers are instructed to keep in check their assumptions about who they think provided the response. This report could contain comments from any of the faculty who left or were retained voluntarily in the prior three years. This typically provides a large enough sample for plausible deniability of identification. In the experience of COACHE researchers, many administrators who are certain that they know “who said what” are, in fact, quite wrong.
            </p>
            <p>
                Finally, if the reader does happen across a comment that directly identifies its author, please contact COACHE by phone at (617) 496-9348 so that we may update our database with a properly redacted replacement.
            </p>

            <h4 className="italicize-sub-header">Strategies for disseminating these comments
            </h4>
            <p>
                Not in spite of their candor but because of it, these qualitative data should be used to complement presentations, briefings, and other syntheses of data about the faculty experience at your institution. Other institutions in this and other COACHE studies have taken the following approaches.
            </p>
            <ul>
                <li>
                    Selectively include comments for public presentations of lessons learned from the survey.
                </li>
                <li>
                    Take an additional pass at redacting comments, then (following a primer on confidentiality) share a “clean,” revised report with key administrators and faculty leaders.
                </li>
                <li>
                    Employ a graduate student to conduct and present a qualitative, thematic analysis of the text responses for broad circulation.
                </li>
                <li>
                    In environments where trust is especially low between faculty and administrators, invite faculty to appoint an individual—preferably someone with experience and the skillset (e.g., qualitative methods, CITI training) to handle these data with the proper care—to sign and submit another Statement of Data Confidentiality to COACHE.
                </li>
            </ul>


        </>
    )
}

export default YourCommitmentToConfidentiality;