import { FC, useState } from "react";
import { Box, Drawer, Toolbar, Divider, IconButton } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import SidebarNavigationList from "./SidebarNavigationList";

interface SectionState {
    [section: string]: boolean;
}

const drawerWidth = 250;

interface SidebarProps {
    sectionState: SectionState;
    toggleSection: (section: string) => void;
}

const Sidebar: FC<SidebarProps> = ({ sectionState, toggleSection }) => {
    const [mobileOpen, setMobileOpen] = useState(false);
    const [isClosing, setIsClosing] = useState(false);

    const handleDrawerClose = () => {
        setIsClosing(true);
        setMobileOpen(false);
    };

    const handleDrawerTransitionEnd = () => {
        setIsClosing(false);
    };

    const handleDrawerToggle = () => {
        if (!isClosing) {
            setMobileOpen(!mobileOpen);
        }
    };

    return (
        <div className="sidebar">
            <Box
                component="nav"
                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2 }}
                    >
                        <MenuIcon />
                    </IconButton>
                </Toolbar>
                <Drawer
                    sx={{
                        width: drawerWidth,
                        display: { xs: 'none', sm: 'none', md: 'block' },
                        '& .MuiDrawer-paper': {
                            boxSizing: 'border-box',
                            width: drawerWidth
                        },
                    }}
                    variant="permanent"
                    anchor="left"
                    open
                >
                    <Toolbar />
                    <Divider />
                    <SidebarNavigationList
                        sectionState={sectionState}
                        toggleSection={toggleSection}
                    />
                    <Divider />
                </Drawer>

                {/* Use the temporary drawer component on smaller screens */}
                <Drawer
                    variant="temporary"
                    anchor="left"
                    open={mobileOpen}
                    onTransitionEnd={handleDrawerTransitionEnd}
                    onClose={handleDrawerClose}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        width: drawerWidth,
                        display: { xs: 'block', sm: 'block', md: 'none' },
                        '& .MuiDrawer-paper': {
                            boxSizing: 'border-box',
                            width: drawerWidth
                        },
                    }}
                >
                    <Toolbar />
                    <Divider />
                    <SidebarNavigationList
                        sectionState={sectionState}
                        toggleSection={toggleSection}
                    />
                    <Divider />
                </Drawer>

            </Box>
        </div>
    )
}

export default Sidebar;