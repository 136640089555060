import { FC, KeyboardEvent } from "react";
import { List, ListItemButton, ListItemText, Collapse } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Link, useLocation, useParams } from "react-router-dom";

interface SectionState {
    [section: string]: boolean;
}


interface SidebarNavigationListProps {
    sectionState: SectionState;
    toggleSection: (section: string) => void;
}

const SidebarNavigationList: FC<SidebarNavigationListProps> = ({ sectionState, toggleSection }) => {
    const { pathname } = useLocation();
    const { reportType, year } = useParams();

    const splitPathname = pathname.split("/");
    const reportTypeAndYearPathname = splitPathname.slice(Math.max(splitPathname.length - 2, 0)).join(" ");

    const handleKeyPress = (event: KeyboardEvent, section: string,) => {
        if (event.key === "Enter" || event.key === " ") {
            toggleSection(section);
        }
    };

    return (
        <List>
            <li>
                <Link className="route-links" to="/">
                    <ListItemButton selected={"/" === pathname}>
                        <ListItemText primary="Report Home" />
                    </ListItemButton>
                </Link>
            </li>
            <li>
                <Link className="route-links" to="AboutThisReport">
                    <ListItemButton selected={reportTypeAndYearPathname === `${reportType} ${year}` || pathname.includes(`/${year}/AboutThisReport`)}>
                        <ListItemText primary="About This Report" />
                    </ListItemButton>
                </Link>
            </li>
            <li>
                <Link className="route-links" to="TheAnalyticSample/Introduction">
                    <ListItemButton
                        onKeyDown={(e) => handleKeyPress(e, "The Analytic Sample")}
                        selected={pathname.includes(`/${year}/TheAnalyticSample/Introduction`)}
                    >
                        <ListItemText primary="1. The Analytic Sample" />
                        <span className="toggle-span" onClick={() => toggleSection("The Analytic Sample")}>
                            {sectionState["The Analytic Sample"] ? <ExpandLess /> : <ExpandMore />}
                        </span>
                    </ListItemButton>
                </Link>
            </li>

            <Collapse in={sectionState["The Analytic Sample"]} timeout="auto" unmountOnExit>
                <List disablePadding>
                    <li>
                        <Link className="route-links" to="TheAnalyticSample/DemographicCharacteristics">
                            <ListItemButton sx={{ pl: 4 }}
                                selected={pathname.includes(`/${year}/TheAnalyticSample/DemographicCharacteristics`)}
                            >
                                <ListItemText primary="1.1 Demographic characteristics" />
                            </ListItemButton>
                        </Link>
                    </li>
                    <li>
                        <Link className="route-links" to="TheAnalyticSample/InstitutionalPersistence">
                            <ListItemButton sx={{ pl: 4 }}
                                selected={pathname.includes(`/${year}/TheAnalyticSample/InstitutionalPersistence`)}
                            >
                                <ListItemText primary="1.2 Institutional persistence" />
                            </ListItemButton>
                        </Link>
                    </li>
                    <li>
                        <Link className="route-links" to="TheAnalyticSample/ResponseRates">
                            <ListItemButton sx={{ pl: 4 }}
                                selected={pathname.includes(`/${year}/TheAnalyticSample/ResponseRates`)}
                            >
                                <ListItemText primary="1.3 Response rates" />
                            </ListItemButton>
                        </Link>
                    </li>
                </List>
            </Collapse>

            <li>
                <Link className="route-links" to="WeighingTheFactors/Introduction">
                    <ListItemButton
                        onKeyDown={(e) => handleKeyPress(e, "Weighing The Factors")}
                        selected={pathname.includes(`/${year}/WeighingTheFactors/Introduction`)}
                    >
                        <ListItemText primary="2. Weighing the Factors" />
                        <span className="toggle-span" onClick={() => toggleSection("Weighing The Factors")}>
                            {sectionState["Weighing The Factors"] ? <ExpandLess /> : <ExpandMore />}
                        </span>
                    </ListItemButton>
                </Link>
            </li>
            <Collapse in={sectionState["Weighing The Factors"]} timeout="auto" unmountOnExit>
                <List disablePadding>
                    <li>
                        <Link className="route-links" to="WeighingTheFactors/AverageRank">
                            <ListItemButton sx={{ pl: 4 }}
                                selected={pathname.includes(`/${year}/WeighingTheFactors/AverageRank`)}
                            >
                                <ListItemText primary="2.1 Average rank of factors compelling faculty to stay or leave" />
                            </ListItemButton>
                        </Link>
                    </li>
                    <li>
                        <Link className="route-links" to="WeighingTheFactors/TopFactors">
                            <ListItemButton sx={{ pl: 4 }}
                                selected={pathname.includes(`/${year}/WeighingTheFactors/TopFactors`)}
                            >
                                <ListItemText primary="2.2 Top factors in the decision to stay or leave" />
                            </ListItemButton>
                        </Link>
                    </li>
                    <li>
                        <Link className="route-links" to="WeighingTheFactors/Comments">
                            <ListItemButton sx={{ pl: 4 }}
                                selected={pathname.includes(`/${year}/WeighingTheFactors/Comments`)}
                            >
                                <ListItemText primary="2.3 Comments on whether to stay or accept the outside offer (Open-text Response)" />
                            </ListItemButton>
                        </Link>
                    </li>
                    <li>
                        <Link className="route-links" to="WeighingTheFactors/ResponsesToOtherPrompts">
                            <ListItemButton sx={{ pl: 4 }}
                                selected={pathname.includes(`/${year}/WeighingTheFactors/ResponsesToOtherPrompts`)}
                            >
                                <ListItemText primary='Responses to "Other (please specify)"' />
                            </ListItemButton>
                        </Link>
                    </li>
                </List>
            </Collapse>


            <li>
                <Link className="route-links" to="Spouses&Partners/Introduction">
                    <ListItemButton
                        onKeyDown={(e) => handleKeyPress(e, "Spouses & Partners")}
                        selected={pathname.includes(`/${year}/Spouses&Partners/Introduction`)}
                    >
                        <ListItemText primary="3. Spouses & Partners" />
                        <span className="toggle-span" onClick={() => toggleSection("Spouses & Partners")}>
                            {sectionState["Spouses & Partners"] ? <ExpandLess /> : <ExpandMore />}
                        </span>
                    </ListItemButton>
                </Link>
            </li>
            <Collapse in={sectionState["Spouses & Partners"]} timeout="auto" unmountOnExit>
                <List disablePadding>
                    <li>
                        <Link className="route-links" to="Spouses&Partners/MaritalStatus">
                            <ListItemButton sx={{ pl: 4 }}
                                selected={pathname.includes(`/${year}/Spouses&Partners/MaritalStatus`)}
                            >
                                <ListItemText primary="3.1 Marital status and spousal/partner employment" />
                            </ListItemButton>
                        </Link>
                    </li>
                    <li>
                        <Link className="route-links" to="Spouses&Partners/Career">
                            <ListItemButton sx={{ pl: 4 }}
                                onKeyDown={(e) => handleKeyPress(e, "Spouses & Partners/Career")}
                                selected={pathname.includes(`/${year}/Spouses&Partners/Career`)}
                            >
                                <ListItemText primary="3.2 Role of spouse/partner career in search and outside offer" />
                                <span className="toggle-span" onClick={() => toggleSection("Spouses & Partners/Career")}>
                                    {sectionState["Spouses & Partners/Career"] ? <ExpandLess /> : <ExpandMore />}
                                </span>
                            </ListItemButton>
                        </Link>
                    </li>

                    <Collapse in={sectionState["Spouses & Partners/Career"]} timeout="auto" unmountOnExit>
                        <List disablePadding>
                            <Link className="route-links" to="Spouses&Partners/ResponsesToOtherPromptsCareer">
                                <ListItemButton sx={{ pl: 6 }}
                                    selected={pathname.includes(`/${year}/Spouses&Partners/ResponsesToOtherPromptsCareer`)}
                                >
                                    <ListItemText primary="Responses to “Other (please specify)”" />
                                </ListItemButton>
                            </Link>
                        </List>
                    </Collapse>


                    <li>
                        <Link className="route-links" to="Spouses&Partners/EmploymentStatus">
                            <ListItemButton sx={{ pl: 4 }}
                                selected={pathname.includes(`/${year}/Spouses&Partners/EmploymentStatus`)}
                            >
                                <ListItemText primary="3.3 Employment status of spouses/partners" />
                            </ListItemButton>
                        </Link>
                    </li>
                    <li>
                        <Link className="route-links" to="Spouses&Partners/Positions">
                            <ListItemButton sx={{ pl: 4 }}
                                onKeyDown={(e) => handleKeyPress(e, "Spouses & Partners/Positions")}
                                selected={pathname.includes(`/${year}/Spouses&Partners/Positions`)}
                            >
                                <ListItemText primary="3.4 Type of positions held by spouses/partners" />
                                <span className="toggle-span" onClick={() => toggleSection("Spouses & Partners/Positions")}>
                                    {sectionState["Spouses & Partners/Positions"] ? <ExpandLess /> : <ExpandMore />}
                                </span>
                            </ListItemButton>
                        </Link>
                    </li>


                    <Collapse in={sectionState["Spouses & Partners/Positions"]} timeout="auto" unmountOnExit>
                        <List disablePadding>
                            <Link className="route-links" to="Spouses&Partners/ResponsesToOtherPromptsPositions">
                                <ListItemButton sx={{ pl: 6 }}
                                    selected={pathname.includes(`/${year}/Spouses&Partners/ResponsesToOtherPromptsPositions`)}
                                >
                                    <ListItemText primary="Responses to “Other (please specify)”" />
                                </ListItemButton>
                            </Link>
                        </List>
                    </Collapse>


                </List>
            </Collapse>

            <li>
                <Link className="route-links" to="Search/Introduction">
                    <ListItemButton
                        onKeyDown={(e) => handleKeyPress(e, "The Search")}
                        selected={pathname.includes(`/${year}/Search/Introduction`)}
                    >
                        <ListItemText primary="4. The Search" />
                        <span className="toggle-span" onClick={() => toggleSection("The Search")}>
                            {sectionState["The Search"] ? <ExpandLess /> : <ExpandMore />}
                        </span>
                    </ListItemButton>
                </Link>
            </li>

            <Collapse in={sectionState["The Search"]} timeout="auto" unmountOnExit>
                <List disablePadding>
                    <li>
                        <Link className="route-links" to="Search/Time">
                            <ListItemButton sx={{ pl: 4 }}
                                selected={pathname.includes(`/${year}/Search/Time`)}
                            >
                                <ListItemText primary="4.1 Time spent considering leaving/spent searching" />
                            </ListItemButton>
                        </Link>
                    </li>
                    <li>
                        <Link className="route-links" to="Search/Motivation">
                            <ListItemButton sx={{ pl: 4 }}
                                selected={pathname.includes(`/${year}/Search/Motivation`)}
                            >
                                <ListItemText primary="4.2 Ultimate motivation for the search" />
                            </ListItemButton>
                        </Link>
                    </li>
                    <li>
                        <Link className="route-links" to="Search/ApplicationsAndOffers">
                            <ListItemButton sx={{ pl: 4 }}
                                selected={pathname.includes(`/${year}/Search/ApplicationsAndOffers`)}
                            >
                                <ListItemText primary="4.3 Applications sent and offers received" />
                            </ListItemButton>
                        </Link>
                    </li>

                    <li>
                        <Link className="route-links" to="Search/InitialContact">
                            <ListItemButton sx={{ pl: 4 }}
                                onKeyDown={(e) => handleKeyPress(e, "The Search/Initial Contact")}
                                selected={pathname.includes(`/${year}/Search/InitialContact`)}
                            >
                                <ListItemText primary="4.4 Initial contact between your faculty and offering institution" />
                                <span className="toggle-span" onClick={() => toggleSection("Search/Initial Contact")}>
                                    {sectionState["Search/Initial Contact"] ? <ExpandLess /> : <ExpandMore />}
                                </span>
                            </ListItemButton>
                        </Link>
                    </li>

                    <Collapse in={sectionState["Search/Initial Contact"]} timeout="auto" unmountOnExit>
                        <List disablePadding>
                            <Link className="route-links" to="Search/ResponsesToOtherPromptsInitialContact">
                                <ListItemButton sx={{ pl: 6 }}
                                    selected={pathname.includes(`/${year}/Search/ResponsesToOtherPromptsInitialContact`)}
                                >
                                    <ListItemText primary="Responses to “Other (please specify)”" />
                                </ListItemButton>
                            </Link>
                        </List>
                    </Collapse>
                </List>
            </Collapse>
            <li>
                <Link className="route-links" to="NegotiationProcess/Introduction">
                    <ListItemButton
                        onKeyDown={(e) => handleKeyPress(e, "The Negotiation Process")}
                        selected={pathname.includes(`/${year}/NegotiationProcess/Introduction`)}
                    >
                        <ListItemText primary="5. The Negotiation Process" />
                        <span className="toggle-span" onClick={() => toggleSection("The Negotiation Process")}>
                            {sectionState["The Negotiation Process"] ? <ExpandLess /> : <ExpandMore />}
                        </span>
                    </ListItemButton>
                </Link>
            </li>

            <Collapse in={sectionState["The Negotiation Process"]} timeout="auto" unmountOnExit>
                <List disablePadding>
                    <Link className="route-links" to="NegotiationProcess/FirstNotification">
                        <ListItemButton sx={{ pl: 4 }}
                            selected={pathname.includes(`/${year}/NegotiationProcess/FirstNotification`)}
                        >
                            <ListItemText primary="5.1 Your institution&rsquo;s first notification of outside offer" />
                        </ListItemButton>
                    </Link>
                    <Link className="route-links" to="NegotiationProcess/Seriousness">
                        <ListItemButton sx={{ pl: 4 }}
                            selected={pathname.includes(`/${year}/NegotiationProcess/Seriousness`)}
                        >
                            <ListItemText primary="5.2 Seriousness with which faculty viewed the outside offer" />
                        </ListItemButton>
                    </Link>
                    <Link className="route-links" to="NegotiationProcess/CounterofferNotification">
                        <ListItemButton sx={{ pl: 4 }}
                            selected={pathname.includes(`/${year}/NegotiationProcess/CounterofferNotification`)}
                        >
                            <ListItemText primary="5.3 Number of days from notification to counteroffer" />
                        </ListItemButton>
                    </Link>
                    <Link className="route-links" to="NegotiationProcess/YourInstitutionsHandling">
                        <ListItemButton sx={{ pl: 4 }}
                            selected={pathname.includes(`/${year}/NegotiationProcess/YourInstitutionsHandling`)}
                        >
                            <ListItemText primary="5.4 Your institution&rsquo;s handling of the process (Open-text Response)" />
                        </ListItemButton>
                    </Link>
                </List>
            </Collapse>

            <li>
                <Link className="route-links" to="NegotiationTerms/Introduction">
                    <ListItemButton
                        onKeyDown={(e) => handleKeyPress(e, "The Negotiation Terms")}
                        selected={pathname.includes(`/${year}/NegotiationTerms/Introduction`)}
                    >
                        <ListItemText primary="6. The Negotiation Terms" />
                        <span className="toggle-span" onClick={() => toggleSection("The Negotiation Terms")}>
                            {sectionState["The Negotiation Terms"] ? <ExpandLess /> : <ExpandMore />}
                        </span>
                    </ListItemButton>
                </Link>
            </li>

            <Collapse in={sectionState["The Negotiation Terms"]} timeout="auto" unmountOnExit>
                <List disablePadding>
                    <li>
                        <Link className="route-links" to="NegotiationTerms/OriginOutsideOffers">
                            <ListItemButton sx={{ pl: 4 }}
                                selected={pathname.includes(`/${year}/NegotiationTerms/OriginOutsideOffers`)}
                            >
                                <ListItemText primary="6.1 Origin of outside offers" />
                            </ListItemButton>
                        </Link>
                    </li>
                    <li>
                        <Link className="route-links" to="NegotiationTerms/RankTenureTitle">
                            <ListItemButton sx={{ pl: 4 }}
                                selected={pathname.includes(`/${year}/NegotiationTerms/RankTenureTitle`)}
                            >
                                <ListItemText primary="6.2 Rank, tenure and title: Pre-offer vs. Offer" />
                            </ListItemButton>
                        </Link>
                    </li>
                    <li>
                        <Link className="route-links" to="NegotiationTerms/Compensation">
                            <ListItemButton sx={{ pl: 4 }}
                                selected={pathname.includes(`/${year}/NegotiationTerms/Compensation`)}
                            >
                                <ListItemText primary="6.3 Compensation: Pre-offer vs. Offer vs. Counteroffer" />
                            </ListItemButton>
                        </Link>
                    </li>

                    <li>
                        <Link className="route-links" to="NegotiationTerms/PermissibleUseStartupFund">
                            <ListItemButton sx={{ pl: 4 }}
                                onKeyDown={(e) => handleKeyPress(e, "Negotiation Terms/Permissible Use Startup Fund")}
                                selected={pathname.includes(`/${year}/NegotiationTerms/PermissibleUseStartupFund`)}
                            >
                                <ListItemText primary="6.4 Permissible uses of startup funds in outside offer" />
                                <span className="toggle-span" onClick={() => toggleSection("Negotiation Terms/Permissible Use Startup Fund")}>
                                    {sectionState["Negotiation Terms/Permissible Use Startup Fund"] ? <ExpandLess /> : <ExpandMore />}
                                </span>
                            </ListItemButton>
                        </Link>
                    </li>
                    <Collapse in={sectionState["Negotiation Terms/Permissible Use Startup Fund"]} timeout="auto" unmountOnExit>
                        <List disablePadding>
                            <Link className="route-links" to="NegotiationTerms/ResponsesToOtherPromptsPermissibleUseStartupFund">
                                <ListItemButton sx={{ pl: 6 }}
                                    selected={pathname.includes(`/${year}/NegotiationTerms/ResponsesToOtherPromptsPermissibleUseStartupFund`)}
                                >
                                    <ListItemText primary="Responses to “Other (please specify)”" />
                                </ListItemButton>
                            </Link>
                        </List>
                    </Collapse>


                    <li>
                        <Link className="route-links" to="NegotiationTerms/CounterOffersSoughtReceived">
                            <ListItemButton sx={{ pl: 4 }}
                                onKeyDown={(e) => handleKeyPress(e, "Negotiation Terms/Counter Offers Sought Received")}
                                selected={pathname.includes(`/${year}/NegotiationTerms/CounterOffersSoughtReceived`)}
                            >
                                <ListItemText primary="6.5 Counteroffers sought and counteroffers received" />
                                <span className="toggle-span" onClick={() => toggleSection("Negotiation Terms/Counter Offers Sought Received")}>
                                    {sectionState["Negotiation Terms/Counter Offers Sought Received"] ? <ExpandLess /> : <ExpandMore />}
                                </span>
                            </ListItemButton>
                        </Link>
                    </li>
                    <Collapse in={sectionState["Negotiation Terms/Counter Offers Sought Received"]} timeout="auto" unmountOnExit>
                        <List disablePadding>
                            <Link className="route-links" to="NegotiationTerms/ResponsesToOtherPromptsCounterOffersSoughtReceived">
                                <ListItemButton sx={{ pl: 6 }}
                                    selected={pathname.includes(`/${year}/NegotiationTerms/ResponsesToOtherPromptsCounterOffersSoughtReceived`)}
                                >
                                    <ListItemText primary="Responses to “Other (please specify)”" />
                                </ListItemButton>
                            </Link>
                        </List>
                    </Collapse>

                    <li>
                        <Link className="route-links" to="NegotiationTerms/YourCounterofferProposedChanges">
                            <ListItemButton sx={{ pl: 4 }}
                                onKeyDown={(e) => handleKeyPress(e, "Negotiation Terms/Proposed Changes")}
                                selected={pathname.includes(`/${year}/NegotiationTerms/YourCounterofferProposedChanges`)}
                            >
                                <ListItemText primary="6.6 Your counteroffer: Proposed changes" />
                                <span className="toggle-span" onClick={() => toggleSection("Negotiation Terms/Proposed Changes")}>
                                    {sectionState["Negotiation Terms/Proposed Changes"] ? <ExpandLess /> : <ExpandMore />}
                                </span>
                            </ListItemButton>
                        </Link>
                    </li>
                    <Collapse in={sectionState["Negotiation Terms/Proposed Changes"]} timeout="auto" unmountOnExit>
                        <List disablePadding>
                            <Link className="route-links" to="NegotiationTerms/ResponsesToOtherPromptsYourCounterofferProposedChanges">
                                <ListItemButton sx={{ pl: 6 }}
                                    selected={pathname.includes(`/${year}/NegotiationTerms/ResponsesToOtherPromptsYourCounterofferProposedChanges`)}
                                >
                                    <ListItemText primary="Responses to “Other (please specify)”" />
                                </ListItemButton>
                            </Link>
                        </List>
                    </Collapse>

                    <li>
                        <Link className="route-links" to="NegotiationTerms/YourCounterofferExtentMatched">
                            <ListItemButton sx={{ pl: 4 }}
                                selected={pathname.includes(`/${year}/NegotiationTerms/YourCounterofferExtentMatched`)}
                            >
                                <ListItemText primary="6.7 Your counteroffer: Extent to which it matched outside offer" />
                            </ListItemButton>
                        </Link>
                    </li>
                    <li>
                        <Link className="route-links" to="NegotiationTerms/YourCounterofferFacultyAssessmentOfComparison">
                            <ListItemButton sx={{ pl: 4 }}
                                selected={pathname.includes(`/${year}/NegotiationTerms/YourCounterofferFacultyAssessmentOfComparison`)}
                            >
                                <ListItemText primary="6.8 Your counteroffer: Faculty assessment of comparison (Open-text Response)" />
                            </ListItemButton>
                        </Link>
                    </li>
                    <li>
                        <Link className="route-links" to="NegotiationTerms/YourCounterofferWhyItDidNotMaterialize">
                            <ListItemButton sx={{ pl: 4 }}
                                selected={pathname.includes(`/${year}/NegotiationTerms/YourCounterofferWhyItDidNotMaterialize`)}
                            >
                                <ListItemText primary="6.9 Your counteroffer: Why it did not materialize (Open-text Response)" />
                            </ListItemButton>
                        </Link>
                    </li>
                </List>
            </Collapse>
            <li>
                <Link className="route-links" to="TheTransition/Introduction">
                    <ListItemButton
                        onKeyDown={(e) => handleKeyPress(e, "The Transition")}
                        selected={pathname.includes(`/${year}/TheTransition/Introduction`)}
                    >
                        <ListItemText primary="7. The Transition" />
                        <span className="toggle-span" onClick={() => toggleSection("The Transition")}>
                            {sectionState["The Transition"] ? <ExpandLess /> : <ExpandMore />}
                        </span>
                    </ListItemButton>
                </Link>
            </li>

            <Collapse in={sectionState["The Transition"]} timeout="auto" unmountOnExit>
                <List disablePadding>
                    <li>
                        <Link className="route-links" to="TheTransition/Treatment">
                            <ListItemButton sx={{ pl: 4 }}
                                selected={pathname.includes(`/${year}/TheTransition/Treatment`)}
                            >
                                <ListItemText primary="7.1 Treatment of faculty after departure intentions are known" />
                            </ListItemButton>
                        </Link>
                    </li>
                    <li>
                        <Link className="route-links" to="TheTransition/TextBasedResponse">
                            <ListItemButton sx={{ pl: 4 }}
                                selected={pathname.includes(`/${year}/TheTransition/TextBasedResponse`)}
                            >
                                <ListItemText primary="7.2 Treatment of faculty after departure intentions are known (Open-text Response)" />
                            </ListItemButton>
                        </Link>
                    </li>
                </List>
            </Collapse>
            <li>
                <Link className="route-links" to="OverallImpressions/Introduction">
                    <ListItemButton
                        onKeyDown={(e) => handleKeyPress(e, "Overall Impressions")}
                        selected={pathname.includes(`/${year}/OverallImpressions/Introduction`)}
                    >
                        <ListItemText primary="8. Overall Impressions" />
                        <span className="toggle-span" onClick={() => toggleSection("Overall Impressions")}>
                            {sectionState["Overall Impressions"] ? <ExpandLess /> : <ExpandMore />}
                        </span>
                    </ListItemButton>
                </Link>
            </li>

            <Collapse in={sectionState["Overall Impressions"]} timeout="auto" unmountOnExit>
                <List disablePadding>
                    <li>
                        <Link className="route-links" to="OverallImpressions/TopChange">
                            <ListItemButton sx={{ pl: 4 }}
                                onKeyDown={(e) => handleKeyPress(e, "Overall Impressions/Top Change")}
                                selected={pathname.includes(`/${year}/OverallImpressions/TopChange`)}
                            >
                                <ListItemText primary="8.1 The top change to convince faculty to stay" />
                                <span className="toggle-span" onClick={() => toggleSection("Overall Impressions/Top Change")}>
                                    {sectionState["Overall Impressions/Top Change"] ? <ExpandLess /> : <ExpandMore />}
                                </span>
                            </ListItemButton>
                        </Link>
                    </li>
                    <Collapse in={sectionState["Overall Impressions/Top Change"]} timeout="auto" unmountOnExit>
                        <List disablePadding>
                            <Link className="route-links" to="OverallImpressions/ResponsesToOtherPromptsTopChange">
                                <ListItemButton sx={{ pl: 6 }}
                                    selected={pathname.includes(`/${year}/OverallImpressions/ResponsesToOtherPromptsTopChange`)}
                                >
                                    <ListItemText primary="Responses to “Other (please specify)”" />
                                </ListItemButton>
                            </Link>
                        </List>
                    </Collapse>
                    <li>
                        <Link className="route-links" to="OverallImpressions/Satisfaction">
                            <ListItemButton sx={{ pl: 4 }}
                                selected={pathname.includes(`/${year}/OverallImpressions/Satisfaction`)}
                            >
                                <ListItemText primary="8.2 Satisfaction with retention efforts" />
                            </ListItemButton>
                        </Link>
                    </li>
                    <li>
                        <Link className="route-links" to="OverallImpressions/Recommendation">
                            <ListItemButton sx={{ pl: 4 }}
                                selected={pathname.includes(`/${year}/OverallImpressions/Recommendation`)}
                            >
                                <ListItemText primary="8.3 Recommendation of department as a place to work
" />
                            </ListItemButton>
                        </Link>
                    </li>
                    <li>
                        <Link className="route-links" to="OverallImpressions/Likelihood">
                            <ListItemButton sx={{ pl: 4 }}
                                selected={pathname.includes(`/${year}/OverallImpressions/Likelihood`)}
                            >
                                <ListItemText primary="8.4 Likelihood to seek or entertain a job offer" />
                            </ListItemButton>
                        </Link>
                    </li>
                    <li>
                        <Link className="route-links" to="OverallImpressions/TextBasedResponses">
                            <ListItemButton sx={{ pl: 4 }}
                                selected={pathname.includes(`/${year}/OverallImpressions/TextBasedResponses`)}
                            >
                                <ListItemText primary="8.5 Additional comments about decision to stay or accept outside offer (Open-text Response)" />
                            </ListItemButton>
                        </Link>
                    </li>
                </List>
            </Collapse>
            <li>
                <Link className="route-links" to="PreemptiveRetentions/Introduction">
                    <ListItemButton
                        onKeyDown={(e) => handleKeyPress(e, "Preemptive Retentions")}
                        selected={pathname.includes(`/${year}/PreemptiveRetentions/Introduction`)}
                    >
                        <ListItemText primary="9. Preemptive Retentions" />
                        <span className="toggle-span" onClick={() => toggleSection("Preemptive Retentions")}>
                            {sectionState["Preemptive Retentions"] ? <ExpandLess /> : <ExpandMore />}
                        </span>
                    </ListItemButton>
                </Link>
            </li>

            <Collapse in={sectionState["Preemptive Retentions"]} timeout="auto" unmountOnExit>
                <List disablePadding>

                    <li>
                        <Link className="route-links" to="PreemptiveRetentions/ProposedChanges">
                            <ListItemButton sx={{ pl: 4 }}
                                onKeyDown={(e) => handleKeyPress(e, "PreemptiveRetentions/ProposedChanges")}
                                selected={pathname.includes(`/${year}/PreemptiveRetentions/ProposedChanges`)}
                            >
                                <ListItemText primary="9.1 Preemptive retentions: Proposed changes" />
                                <span className="toggle-span" onClick={() => toggleSection("Preemptive Retentions/Proposed Changes")}>
                                    {sectionState["Preemptive Retentions/Proposed Changes"] ? <ExpandLess /> : <ExpandMore />}
                                </span>
                            </ListItemButton>
                        </Link>
                    </li>
                    <Collapse in={sectionState["Preemptive Retentions/Proposed Changes"]} timeout="auto" unmountOnExit>
                        <List disablePadding>
                            <Link className="route-links" to="PreemptiveRetentions/ResponsesToOtherPromptsProposedChanges">
                                <ListItemButton sx={{ pl: 6 }}
                                    selected={pathname.includes(`/${year}/PreemptiveRetentions/ResponsesToOtherPromptsProposedChanges`)}
                                >
                                    <ListItemText primary="Responses to “Other (please specify)”" />
                                </ListItemButton>
                            </Link>
                        </List>
                    </Collapse>
                    <li>
                        <Link className="route-links" to="PreemptiveRetentions/Comments">
                            <ListItemButton sx={{ pl: 4 }}
                                selected={pathname.includes(`/${year}/PreemptiveRetentions/Comments`)}
                            >
                                <ListItemText primary="9.2 Comments about changes to work or employment made in a preemptive action (Open-text Response)" />
                            </ListItemButton>
                        </Link>
                    </li>
                    <li>
                        <Link className="route-links" to="PreemptiveRetentions/AdditionalDetails">
                            <ListItemButton sx={{ pl: 4 }}
                                selected={pathname.includes(`/${year}/PreemptiveRetentions/AdditionalDetails`)}
                            >
                                <ListItemText primary="9.3 Additional details about actions taken preemptively to retain faculty (Open-text Response)" />
                            </ListItemButton>
                        </Link>
                    </li>
                </List>
            </Collapse>

            <li>
                <Link className="route-links" to="Appendix/BriefSummary">
                    <ListItemButton
                        onKeyDown={(e) => handleKeyPress(e, "Appendix")}
                        selected={pathname.includes(`/${year}/Appendix/Introduction`)}
                    >
                        <ListItemText primary="Appendix" />
                        <span className="toggle-span" onClick={() => toggleSection("Appendix")}>
                            {sectionState["Appendix"] ? <ExpandLess /> : <ExpandMore />}
                        </span>
                    </ListItemButton>
                </Link>
            </li>

            <Collapse in={sectionState["Appendix"]} timeout="auto" unmountOnExit>
                <List disablePadding>
                    <li>
                        <Link className="route-links" to="Appendix/BriefSummary">
                            <ListItemButton sx={{ pl: 4 }}
                                selected={pathname.includes(`/${year}/Appendix/BriefSummary`)}
                            >
                                <ListItemText primary="Brief summary of the COACHE Faculty Retention & Exit Study" />
                            </ListItemButton>
                        </Link>
                    </li>
                    <li>
                        <Link className="route-links" to="Appendix/YourCommitmentToConfidentiality">
                            <ListItemButton sx={{ pl: 4 }}
                                selected={pathname.includes(`/${year}/Appendix/YourCommitmentToConfidentiality`)}
                            >
                                <ListItemText primary="Your commitment to confidentiality" />
                            </ListItemButton>
                        </Link>
                    </li>
                    <li>
                        <Link className="route-links" to="Appendix/EligiblePopulation">
                            <ListItemButton sx={{ pl: 4 }}
                                selected={pathname.includes(`/${year}/Appendix/EligiblePopulation`)}
                            >
                                <ListItemText primary="Eligible population" />
                            </ListItemButton>
                        </Link>
                    </li>
                    <li>
                        <Link className="route-links" to="Appendix/Administration">
                            <ListItemButton sx={{ pl: 4 }}
                                selected={pathname.includes(`/${year}/Appendix/Administration`)}
                            >
                                <ListItemText primary="Administration" />
                            </ListItemButton>
                        </Link>
                    </li>
                    <li>
                        <Link className="route-links" to="Appendix/ReportingAnalysis">
                            <ListItemButton sx={{ pl: 4 }}
                                selected={pathname.includes(`/${year}/Appendix/ReportingAnalysis`)}
                            >
                                <ListItemText primary="Reporting/Analysis" />
                            </ListItemButton>
                        </Link>
                    </li>
                    <li>
                        <Link className="route-links" to="Appendix/References">
                            <ListItemButton sx={{ pl: 4 }}
                                selected={pathname.includes(`/${year}/Appendix/References`)}
                            >
                                <ListItemText primary="References" />
                            </ListItemButton>
                        </Link>
                    </li>
                    <li>
                        <Link className="route-links" to="Appendix/Contact">
                            <ListItemButton sx={{ pl: 4 }}
                                selected={pathname.includes(`/${year}/Appendix/Contact`)}
                            >
                                <ListItemText primary="How to contact COACHE" />
                            </ListItemButton>
                        </Link>
                    </li>
                    <li>
                        <Link className="route-links" to="Appendix/TheAnalyticSample-GrantsFromExternalSources">
                            <ListItemButton sx={{ pl: 4 }}
                                selected={pathname.includes(`/${year}/Appendix/TheAnalyticSample-GrantsFromExternalSources`)}
                            >
                                <ListItemText primary="Grants from external sources" />
                            </ListItemButton>
                        </Link>
                    </li>
                    <li>
                        <Link className="route-links" to="Appendix/TheAnalyticSample-AcademicAnalytics">
                            <ListItemButton sx={{ pl: 4 }}
                                selected={pathname.includes(`/${year}/Appendix/TheAnalyticSample-AcademicAnalytics`)}
                            >
                                <ListItemText primary="Academic analytics" />
                            </ListItemButton>
                        </Link>
                    </li>
                    <li>
                        <Link className="route-links" to="Appendix/TheAnalyticSample-MissingData">
                            <ListItemButton sx={{ pl: 4 }}
                                selected={pathname.includes(`/${year}/Appendix/TheAnalyticSample-MissingData`)}
                            >
                                <ListItemText primary="Missing data" />
                            </ListItemButton>
                        </Link>
                    </li>
                    <li>
                        <Link className="route-links" to="Appendix/TheAnalyticSample-ResponseRates">
                            <ListItemButton sx={{ pl: 4 }}
                                selected={pathname.includes(`/${year}/Appendix/TheAnalyticSample-ResponseRates`)}
                            >
                                <ListItemText primary="Response rates" />
                            </ListItemButton>
                        </Link>
                    </li>
                    <li>
                        <Link className="route-links" to="Appendix/NegotiationTerms-RankTenureTitle">
                            <ListItemButton sx={{ pl: 4 }}
                                selected={pathname.includes(`/${year}/Appendix/NegotiationTerms-RankTenureTitle`)}
                            >
                                <ListItemText primary="Rank, tenure, and title: Pre-offer vs. Offer" />
                            </ListItemButton>
                        </Link>
                    </li>
                </List>
            </Collapse>
        </List>
    )
}

export default SidebarNavigationList;