import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { getProtectedResource } from "../../services/apiRequest.service";
import { NestedResponseData } from "../../models/responseData";
import { transformDataFactorsAverageRank, TransformedDataFactorsAverageRank } from "../../utils/transformDataUtils";
import FilterDropdown from "../../components/FilterDropdown";
import DotChart from "../../charts/dotChart/dotChart";
import { makeTopFactorsLegend } from "../../utils/legendMapping/topFactorsLegend";
import InstitutionN from "../../components/InstitutionN";
import PageLoader from "../../components/PageLoader";
import ApiResponseToCSVConverter from "../../utils/csvDownload/ApiResponseToCsvConverter";
import { 
  determineResponseTypeNestedResponseData, determineResponseTypeFactorsListResponse 
} from "../../utils/determineResponseType";

interface FactorsResponseObject {
  [key: string]: TransformedDataFactorsAverageRank[];
}

const SubSection4 = () => {

  const [categoryFilter, setCategoryFilter] = useState("exit_status");
  const [apiResponse, setApiResponse] = useState<NestedResponseData | null>(null);

  const [factorsListLegend, setFactorsListLegend] = useState<Record<string, string>>({});

  const [responseObject, setResponseObject] = useState<FactorsResponseObject | null>(null);
  const [institutionN, setInstitutionN] = useState(0);

  const { getAccessTokenSilently } = useAuth0();
  const { year } = useParams();

  useEffect(() => {
    const getFactorsList = async () => {
      const accessToken = await getAccessTokenSilently();
      const requestUrl = `section6/subsection4/list_factors?report_year=${year}`
      const { data } = await getProtectedResource(accessToken, requestUrl);

      if (data && determineResponseTypeFactorsListResponse(data)) {
        setFactorsListLegend(makeTopFactorsLegend(data.all));
      }
    };
    getFactorsList();
  }, [getAccessTokenSilently, year]);

  useEffect(() => {
    const getData = async () => {
      const accessToken = await getAccessTokenSilently();

      for (const factor of Object.keys(factorsListLegend)) {
        const requestUrl = `section6/subsection4/?report_year=${year}&group_by=${categoryFilter}&factor=${factor}`
        const { data, error } = await getProtectedResource(accessToken, requestUrl);

        if (data && determineResponseTypeNestedResponseData(data)) {
          const tempObject = { ...responseObject }
          tempObject[factor] = transformDataFactorsAverageRank(data, factor, categoryFilter);
          setResponseObject(tempObject);


          // combine api responses for csv download
          setApiResponse((prevState: any) => ({
            ...prevState,
            [factor]: data
          }));
        }

        if (error) {
          console.error("Error fetching data:", error);
        }
      };
    }
    getData();
  }, [getAccessTokenSilently, factorsListLegend, categoryFilter, year]);


  useEffect(() => {
    const getInstitutionN = async () => {
      const accessToken = await getAccessTokenSilently();
      const factor = Object.keys(factorsListLegend)[0];
      const requestUrl = `section6/subsection4/?report_year=${year}&group_by=${categoryFilter}&factor=${factor}`
      const { data } = await getProtectedResource(accessToken, requestUrl);

      if (data && determineResponseTypeNestedResponseData(data)) {
        setInstitutionN(data.institution.overall.total);
      }
    };
    if (Object.keys(factorsListLegend)[0] !== undefined) {
      getInstitutionN();
    }
  }, [getAccessTokenSilently, factorsListLegend, categoryFilter, year]);


  return (
    <>
      <h2>6.4 Permissible uses of startup funds in outside offer</h2>
      <p>
        Select the permissible uses of your outside offer&rsquo;s startup funds. Rank order them according to uses that were most important to you, where “1”
        corresponds to the most important use of startup funds.
      </p>
      <p>
        What permissible uses are most frequently included?
      </p>

      {!responseObject && <PageLoader />}

      {responseObject && institutionN &&
        <>
          <InstitutionN institutionN={institutionN} />
          <FilterDropdown setSelectedFilter={setCategoryFilter} />

          {Object.keys(factorsListLegend).map((factor) =>
            <DotChart
              key={factor}
              data={responseObject[factor]}
              selectedFilter={categoryFilter}
              factor={factorsListLegend[factor]}
              institutionN={institutionN}
              maxRanking={9}
            />
          )}
        </>
      }
      {apiResponse &&
        <ApiResponseToCSVConverter
          apiResponse={apiResponse}
          selectedFilter={categoryFilter}
          filename="6.4_Permissible_uses_of_startup_funds"
          section="6.4"
        />}

    </>
  )
}

export default SubSection4;